import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-bootstrap'
import { observer, inject } from 'mobx-react'
import { minutesToString } from '../util'

@inject("store")
@observer
export default class DailySelector extends React.Component {
  static propTypes = {
    store: PropTypes.object.isRequired,
  }

  render() {
    const { store } = this.props
    const { lengthInDays, availableLengthsInDays } = store

    return (
      <div className="length-selector">
        <div className="length-selector-label">
          How many days would you like to book?
        </div>
        <Form.Select
          name="length-in-days"
          value={lengthInDays}
          onChange={e => store.setLengthInDays(e.target.value)}
        >
          { availableLengthsInDays.map(length => 
            <option key={length} value={length}>
              { `${length} days ` }
            </option>
          )}
        </Form.Select>
      </div>
    )
  }
}

