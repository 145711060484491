import React, { useState } from 'react';

const CardButton = ({ onClick, id }) => (
  <button className="credit-card-button" onClick={onClick} id={id} >
    <i className="fa fa-credit-card" />
    { " " }
    Debit or Credit Card
  </button>
)


export default CardButton
