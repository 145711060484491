function calculateinitialPaymentMethodState(paymentMethods) {
  if(paymentMethods && paymentMethods.length === 1 && paymentMethods[0].component === "custom") {
    return paymentMethods[0].id
  }
  else {
    return null
  }
}

export default calculateinitialPaymentMethodState
