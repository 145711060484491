import React from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'

@inject("store")
@observer
class Continue extends React.Component {
  static propTypes = {
    store: PropTypes.object.isRequired
  }

  handleContinue = (e) => {
    const { store } = this.props
    e.preventDefault()
    store.submit()
  }

  handleChooseAnother = (e) => {
    const { store } = this.props
    e.preventDefault()
    store.selectedTime = null
  }

  render() {
    const { store } = this.props

    return (
      <div>
        <div className="time-chooser-date-header">
          <hr />
          {/* <div>You chose { format(store.selectedDate, "MMMM do, yyyy") } at { store.selectedTime }</div> */}
          <div className="continue-wrapper">
            <a className="continue-btn" onClick={this.handleContinue}>
              Continue »
            </a>
          </div>
          <div className="or-back">
            <a href="#" className="or-back-btn" onClick={this.handleChooseAnother}>
              Or choose a different time
            </a>
          </div>
        </div>
      </div>
    )
  }
}

export default Continue
