import React, { useState } from 'react'
import PropTypes from 'prop-types'
import groupPaymentMethodsByFee from './util/groupPaymentMethodsByFee'
import calculateInitialPaymentMethodState from './util/calculateInitialPaymentMethodState'
import PaymentPane from './components/PaymentPane'
import SavedCards from './components/SavedCards'
import StripeForm from './components/StripeForm'
import StripeExpressForm from './components/StripeExpressForm'
import PaypalForm from './components/PaypalForm'
import SquareForm from './components/SquareForm'
import GiftCardForm from './components/GiftCardForm'
import CustomForm from './components/CustomForm'
import Loader from './components/Loader'
import './style.scss';

const components = {
  "card-square": SquareForm,
  "card-stripe": StripeForm,
  "card-stripe-express": StripeExpressForm,
  "paypal": PaypalForm,
  "custom": CustomForm
}

const PaymentForm = (props) => {
  const { paymentMethods, showSavedPaymentMethods, amountFormatted } = props
  const [loading, setLoading] = useState(false)
  const [activePaymentMethod, setActivePaymentMethod] = useState(calculateInitialPaymentMethodState(paymentMethods))

  const paymentMethodsByFee = groupPaymentMethodsByFee(paymentMethods, amountFormatted)
  const showAnyPaymentMethodFees = paymentMethodsByFee.filter(group => group.feeDescription).length > 0

  function renderPaymentMethod(paymentMethod) {
    const Component = components[paymentMethod.component]

    if(!Component) {
      return null
    }

    return (
      <Component
        {...props}
        amountCents={paymentMethod.totalWithFeesCents}
        amountCurrency={paymentMethod.totalWithFeesCurrency}
        amountFormatted={paymentMethod.totalWithFeesFormatted}
        paymentMethod={paymentMethod}
        loading={loading}
        setLoading={setLoading}
        show={!activePaymentMethod || activePaymentMethod === paymentMethod.id}
        activePaymentMethod={activePaymentMethod}
        setActivePaymentMethod={setActivePaymentMethod}
        showBackToAllPaymentMethods={!calculateInitialPaymentMethodState(paymentMethods)}
      />
    )
  }

  function renderSavedCardsFor(paymentMethod) {
    if(!showSavedPaymentMethods || !paymentMethod.supportsSavedCards) {
      return null
    }

    return (
      <SavedCards
        {...props}
        loading={loading}
        setLoading={setLoading}
        show={!activePaymentMethod || activePaymentMethod === "saved-cards"}
        activePaymentMethod={activePaymentMethod}
        setActivePaymentMethod={setActivePaymentMethod}
      />
    )
  }

  function shouldShowPane(group) {
    if(!activePaymentMethod) {
      return true
    }
    else if(group.paymentMethods.find(paymentMethod => paymentMethod.id === activePaymentMethod)) {
      return true
    }
    else if(activePaymentMethod === "saved-cards" && group.paymentMethods.find(paymentMethod => paymentMethod.supportsSavedCards)) {
      return true
    }
    else {
      return false
    }
  }

  return (
    <div className={`checkcherry-payment-widget ${activePaymentMethod}-active`}>
      <Loader loading={loading}>
        { paymentMethodsByFee.map(group => 
        <PaymentPane
          key={group.totalWithFeesFormatted}
          showFees={showAnyPaymentMethodFees}
          totalWithFeesFormatted={group.totalWithFeesFormatted}
          feeDescription={group.feeDescription}
          feeAmountFormatted={group.feeAmountFormatted}
          show={shouldShowPane(group)}
        >
          { group.paymentMethods.map(paymentMethod => 
            <div key={paymentMethod.id}>
              { renderSavedCardsFor(paymentMethod) }
              { renderPaymentMethod(paymentMethod) }
            </div>
          )}
        </PaymentPane>
        )}
      </Loader>
    </div>
  )
}

PaymentForm.defaultProps = {
  allowPayLater: true
}

export default PaymentForm
