function groupPaymentMethodsByFee(paymentMethods, totalFormatted) {
  const items = []

  paymentMethods.forEach(paymentMethod => {
    const { feeAmountFormatted, totalWithFeesFormatted, feeDescription } = paymentMethod
    const item = items.find(item => item.feeAmountFormatted === feeAmountFormatted)

    if(item) {
      item.paymentMethods.push(paymentMethod)
    }
    else {
      items.push({
        feeDescription,
        feeAmountFormatted,
        totalWithFeesFormatted,
        totalFormatted,
        paymentMethods: [paymentMethod]
      })
    }
  })

  return items
}

export default groupPaymentMethodsByFee
