import React from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import { toJS } from 'mobx'
import ChecklistItemEditor from './ChecklistItemEditor'
import DueDateLabel from './DueDateLabel'
import UserPreview from './UserPreview'
import TaskableLabel from './TaskableLabel'
import SimpleFormat from '@16g/react-simple-format'
import { parseDate, formatCompact } from '../util'
import extractErrors from '../util/extractErrors'

@inject("checklistStore")
@observer
export default class ChecklistItem extends React.Component {
  static propTypes = {
    showCheckables: PropTypes.bool.isRequired,
    item: PropTypes.shape({
      attributes: PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        notes: PropTypes.string,
        checked: PropTypes.bool.isRequired,
        userIds: PropTypes.arrayOf(PropTypes.number)
      })
    })
  }

  state = {
    showDetails: false,
    loading: false,
    errors: {},
    showChecklistItemEditor: false
  }

  handleCheck = (e) => {
    const { item, checklistStore } = this.props

    e.preventDefault()
    e.stopPropagation()

    if(item.attributes.checked) {
      checklistStore.markItemUnchecked(item.attributes.id)
    }
    else {
      checklistStore.markItemChecked(item.attributes.id)
    }
  }

  handleToggleItemBulkSelected = (e) => {
    const { item, checklistStore } = this.props
    e.preventDefault()
    e.stopPropagation()
    checklistStore.toggleItemBulkSelected(item.id)
  }

  toggleDetails = (e) => {
    e.preventDefault()

    const { item } = this.props
    const { showChecklistItemEditor } = this.state
    const { notes, editable } = item.attributes

    if(notes || editable) {
      if(!showChecklistItemEditor) {
        this.setState({ showDetails: !this.state.showDetails })
      }
    }
  }

  handleDelete = (e) => {
    const { item, checklistStore } = this.props
    e.preventDefault()
    e.stopPropagation()
    if(window.confirm("Are you sure you want to delete this item? This can not be undone!")) {
      checklistStore.deleteItem(item)
    }
  }

  handleEdit = (e) => {
    e.preventDefault();
    e.stopPropagation()
    this.setState({ showChecklistItemEditor: true })
  }

  handleHide = () => {
    this.setState({ showChecklistItemEditor: false })
  }

  handleItemSubmission = async ({ title, notes, dueOn, users }) => {
    const { item, checklistStore } = this.props

    this.setState({ loading: true })

    try {
      await checklistStore.updateItem(item, { title, notes, dueOn, users })
      this.setState({ showChecklistItemEditor: false, loading: false, errors: {}})
    }
    catch(e) {
      const errors = extractErrors(e)
      this.setState({ loading: false, errors })
    }

  }

  render() {
    const { item, provided, checklistStore, showCheckables } = this.props
    const { showChecklistItemEditor, loading, errors, showDetails } = this.state
    const { title, notes, dueOn, checked, taskableDescription, checklistId, checkableId, checkableType, checkableDescription, userIds, checkableStatus, position, editable } = item.attributes

    const bulkSelected = checklistStore.bulkSelectedItemIds.includes(item.id)

    const users = userIds
      .map(userId => checklistStore.users.get(`${userId}`))
      .filter(user => user)
      .map(user => {
        return {
          value: `${user.id}`,
          label: user.attributes.fullName,
          attributes: {...user.attributes}
        }
      })

    return (
      <div
        className={`checklist-item ${checked ? "checked" : "unchecked"} ${showDetails ? "show-details" : "hide-details"}`}
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
      >
        <ChecklistItemEditor
          modalTitle="Edit Item"
          show={showChecklistItemEditor}
          onHide={this.handleHide}
          onSubmit={this.handleItemSubmission}
          title={title}
          notes={notes || ""}
          dueOn={parseDate(dueOn)}
          checkableType={checkableType}
          checkableId={checkableId}
          users={users}
          loading={loading}
          errors={errors}
        />
        <div className="checklist-line-header-wrapper">
          { checklistStore.bulkEditing &&
            <div onClick={this.handleToggleItemBulkSelected} className="checkmark-control bulk-select-wrapper">
              { bulkSelected ?
                <i className="fa fa-check-square-o" />
                :
                <i className="fa fa-square-o" />
              }
            </div>
          }
          <div className="checklist-line-header">
            <div onClick={this.handleCheck} className="checkmark-control">
              { checked ?
                <i className="fa fa-check-square-o" />
                :
                <i className="fa fa-square-o" />
              }
            </div>
            <div className="title" onClick={this.toggleDetails}>
              <span className="title-text"> { title } </span>
              <TaskableLabel taskableDescription={taskableDescription} />
              { userIds &&
                <div className="mt-1">
                  { userIds.map(userId => 
                    <UserPreview userId={userId} key={userId} />
                  )}
                </div>
              }
              <DueDateLabel dueOn={dueOn} />
              { showCheckables &&
                <div className={`event-title ${checkableStatus}`}>
                  { checkableDescription }
                </div>
              }
              { (!showDetails && notes) &&
                <div className="expand-to-see-notes">Expand to see notes</div>
              }
            </div>
            { (editable || notes) &&
              <div>
                <a href="#" onClick={this.toggleDetails} className="show-details-control">
                  { showDetails ?
                    <i className="fa fa-angle-double-up" />
                    :
                    <i className="fa fa-angle-double-down" />
                  }
                </a>
              </div>
            }
          </div>
        </div>
        { showDetails &&
          <div className="checklist-line-body">
            <SimpleFormat text={notes} />
            { editable &&
              <div className="additional-checklist-controls">
                <div className="btn-group">
                  { (showCheckables && checkableType === "Event") &&
                    <a href={`/events/${checkableId}`} className="btn btn-secondary btn-sm">
                      View Event
                    </a>
                  }
                  { (showCheckables && checkableType === "Event") &&
                    <a href={`/events/${checkableId}/checklists/${checklistId}`} className="btn btn-secondary btn-sm">
                      View Checklist
                    </a>
                  }
                  { (showCheckables && checkableType === "User") &&
                    <a href={`/users/${checkableId}/checklist`} className="btn btn-secondary btn-sm">
                      View Checklist
                    </a>
                  }
                  <a href="#" onClick={this.handleEdit} className="btn btn-secondary btn-sm">
                    Edit
                  </a>
                  <a href="#" onClick={this.handleDelete} className="btn btn-secondary btn-sm">
                    Delete
                  </a>
                </div>
              </div>
            }
          </div>
        }
      </div>
    )
  }
}

