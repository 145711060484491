import React from 'react'
import PropTypes from 'prop-types'
import { Form, FormGroup } from 'react-bootstrap'
import { toJS } from 'mobx'
import { observer, inject } from 'mobx-react'
import { minutesToString } from '../util'
import ErrorBlock from './ErrorBlock'

@inject("store")
@inject("formErrors")
@inject("packageUnitRanges")
@observer
export default class UnitRangeSelector extends React.Component {
  static propTypes = {
    store: PropTypes.object.isRequired,
    packageUnitRanges: PropTypes.array
  }

  render() {
    const { store, packageUnitRanges, formErrors } = this.props
    const { units, selectedPackage, packageUnitRangeId } = store
    const { unitsHowMany } = selectedPackage.attributes

    return (
      <FormGroup className="unit-selector">
        <div className="unit-selector-label">
          { unitsHowMany }
        </div>
        <Form.Select
          value={packageUnitRangeId}
          onChange={e => store.setPackageUnitRangeId(e.target.value)}
          isInvalid={formErrors.packageUnitRangeId}
        >
          <option></option>
          { packageUnitRanges.map(packageUnitRange => 
            <option key={packageUnitRange.id} value={packageUnitRange.id}>
              { packageUnitRange.attributes.unitRangeLabel }
            </option>
          )}
        </Form.Select>
        <ErrorBlock error={formErrors.packageUnitRangeId} />
      </FormGroup>
    )
  }
}

