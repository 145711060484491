import React, { useState, useEffect } from 'react'
import ShowAllPaymentMethods from './ShowAllPaymentMethods'
import client from '../../../helpers/client'

function extractErrorMessage(error) {
  if(error && error.response && error.response.data && error.response.data.error) {
    return error.response.data.error
  }
  else {
    return error.message
  }
}

const SavedCardPaymentMethodButton = ({ onClick }) => (
  <button className="custom-button" id={`pay-with-custom-btn-saved-card`} onClick={onClick} >
    Saved Cards
  </button>
)

const SavedCardSelector = ({ savedCard, onClick, active }) => (
  <div className="saved-card-selector-wrapper">
    <div className={`saved-card-selector ${active ? 'active' : 'inactive'}`} onClick={onClick} >
      <div className="brand">{ savedCard.brand }</div>
      <div className="card-number">•••• •••• •••• { savedCard.last4 }</div>
      <div className="expiration">{ savedCard.expMonth }/{ savedCard.expYear }</div>
    </div>
  </div>
)

const SavedCardList = ({ setLoading, onGoBack, userId, paymentIntentId }) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [savedCards, setSavedCards] = useState([])
  const [activeSavedCardId, setActiveSavedCardId] = useState(null)


  useEffect(() => {
    async function fetchSavedCards() {
      setLoading(true)
      try {
        const response = await client.get(`/users/${userId}/saved_credit_cards.json`)
        const savedCards = response.data.data.map(c => c.attributes)
        setSavedCards(savedCards)
        if(!savedCards.length) {
          setErrorMessage("You don't have any saved payment methods on file")
        }
        setLoading(false)
      }
      catch(e) {
        setErrorMessage(e.message)
        setLoading(false)
      }
    }
    fetchSavedCards()
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true)

    try {
      const activeSavedCard = savedCards.find(savedCard => savedCard.id === activeSavedCardId)
      console.log({
        activeSavedCard,
        savedPaymentMethodId: activeSavedCard.id,
        customerId: activeSavedCard.customerId
      })
      switch(activeSavedCard.processor) {
        case "stripe":
          await client.post(`/payment_intents/${paymentIntentId}/submit_stripe_saved_payment_method`, {
            savedPaymentMethodId: activeSavedCard.id,
            customerId: activeSavedCard.customerId
          }, { withCredentials: true })
          window.location.reload()
          break;
        case "stripe_express":
          await client.post(`/payment_intents/${paymentIntentId}/submit_stripe_express_saved_payment_method`, {
            savedPaymentMethodId: activeSavedCard.id,
            customerId: activeSavedCard.customerId
          }, { withCredentials: true })
          window.location.reload()
          break;
        case "square":
          await client.post(`/payment_intents/${paymentIntentId}/submit_square_saved_payment_method`, {
            savedPaymentMethodId: activeSavedCard.id,
            customerId: activeSavedCard.customerId
          }, { withCredentials: true })
          window.location.reload()
          break;
        default:
          throw new Error(`Unknown processor: ${activeSavedCard.processor}`)
      }
    }
    catch(e) {
      setErrorMessage(extractErrorMessage(e))
      setLoading(false)
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      { errorMessage && <div className="card-errors" role="alert">{errorMessage}</div> }

      <SavedCardPaymentMethodButton onClick={onGoBack} />

      <div className="saved-card-collection">
        { savedCards.map((savedCard) =>
          <SavedCardSelector
            key={savedCard.id}
            active={activeSavedCardId === savedCard.id}
            savedCard={savedCard}
            onClick={() => setActiveSavedCardId(savedCard.id)}
          />
        )}
      </div>

      { activeSavedCardId &&
        <button type="submit" className="submit-payment-btn">Submit Payment</button>
      }

      <ShowAllPaymentMethods onClick={onGoBack} showBackToAllPaymentMethods={true} />
    </form>
  )
}

const SavedCards = ({ show, showBackToAllPaymentMethods, paymentMethod, activePaymentMethod, setActivePaymentMethod, setLoading, paymentIntentId, userId }) => {
  if(!show) {
    return null
  }

  return (
    <div>
      { activePaymentMethod === "saved-cards" ?
        <SavedCardList
          userId={userId}
          paymentMethod={paymentMethod}
          setLoading={setLoading}
          paymentIntentId={paymentIntentId}
          onGoBack={() => setActivePaymentMethod(null)}
        />
        :
        <SavedCardPaymentMethodButton
          onClick={() => setActivePaymentMethod("saved-cards")}
        />
      }
    </div>
  )
}

export default SavedCards
