import React, { useState } from 'react'
import ShowAllPaymentMethods from './ShowAllPaymentMethods'
import client from '../../../helpers/client'

const CustomButton = ({ onClick, paymentMethod, selected }) => (
  <button
    className={`custom-button ${selected ? 'selected' : 'unselected'}`}
    id={`pay-with-custom-btn-${paymentMethod.id}`}
    onClick={onClick}
    style={{ backgroundColor: paymentMethod.buttonBackgroundColor, color: paymentMethod.buttonForegroundColor }}
  >
    { paymentMethod.customName }
  </button>
)

const CustomRedemptionForm = ({ paymentMethod, setLoading, onGoBack, paymentIntentId, allowPayLater, showBackToAllPaymentMethods }) => {
  const { customDescriptionHtml } = paymentMethod
  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true)

    try {
      const result = await client.post(`/payment_intents/${paymentIntentId}/submit_custom_payment_indicator`, {
        paymentMethodId: paymentMethod.id
      }, { withCredentials: true })
      window.location.reload()
    }
    catch(e) {
      if(e.response && e.response.data.error) {
        setErrorMessage(e.response.data.error)
      }
      else {
        setErrorMessage(e.message)
      }
      setLoading(false)
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      { errorMessage && <div className="card-errors" role="alert">{errorMessage}</div> }

      <CustomButton onClick={onGoBack} paymentMethod={paymentMethod} selected={true} />

      <div
        className="payment-instructions-box ql-content-wrapper"
        dangerouslySetInnerHTML={{__html: customDescriptionHtml }}
      />


      { allowPayLater && <button type="submit" className="submit-payment-btn">Confirm Booking</button> }

      <ShowAllPaymentMethods onClick={onGoBack} showBackToAllPaymentMethods={showBackToAllPaymentMethods} />
    </form>
  )
}

const CustomForm = ({ show, paymentMethod, activePaymentMethod, setActivePaymentMethod, setLoading, paymentIntentId, allowPayLater, showBackToAllPaymentMethods }) => {
  if(!show) {
    return null
  }

  return (
    <div>
      { activePaymentMethod === paymentMethod.id ?
        <CustomRedemptionForm
          paymentMethod={paymentMethod}
          allowPayLater={allowPayLater}
          setLoading={setLoading}
          paymentIntentId={paymentIntentId}
          showBackToAllPaymentMethods={showBackToAllPaymentMethods}
          onGoBack={() => setActivePaymentMethod(null)}
        />
        :
        <CustomButton
          paymentMethod={paymentMethod}
          onClick={() => setActivePaymentMethod(paymentMethod.id)}
        />
      }
    </div>
  )
}

export default CustomForm
