import React from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import { toJS } from 'mobx'
import scrollToComponent from 'react-scroll-to-component'
import { camelCase } from 'change-case'
import TextField from '../ui/TextField'
import HoneypotField from './HoneypotField'
import SelectField from '../ui/SelectField'
import GroupedSelectField from '../ui/GroupedSelectField'
import AddressField from '../ui/AddressField'
import TextAreaField from '../ui/TextAreaField'
import DateField from '../ui/DateField'
import TimeField from '../ui/TimeField'
import SubmitField from '../ui/SubmitField'
import FlashHandler from './FlashHandler'
import CustomField from './CustomField'
import Address from '../ui/AddressField/Address'


@observer
export default class Form extends React.Component {
  static propTypes = {
    formStore: PropTypes.object.isRequired,
    onSubmit: PropTypes.func,
    labelsAsPlaceholders: PropTypes.bool,
    wideSubmitButtons: PropTypes.bool,
    buttonBackgroundColor: PropTypes.string,
    buttonForegroundColor: PropTypes.string,
    fontFamily: PropTypes.string,
    labelFontSize: PropTypes.string,
    labelFontWeight: PropTypes.string,
    labelColor: PropTypes.string,
  }

  state = {
    loading: false,
    errors: {}
  }

  handleSubmission = (e) => {
    const { onSubmit, formStore } = this.props

    e.preventDefault()

    this.setState({ loading: true })

    formStore.postLead({
      onSuccess: (response) => {
        onSubmit && onSubmit(response.data.data)
      },
      onError: (error) => {
        if(error.response && error.response.status === 422) {
          scrollToComponent(this.formRef, { offset: -150, align: 'top' })
          this.setState({errors: error.response.data.errors, loading: false })
        }
        else {
          throw(error)
        }
      }
    })
  }

  renderField(field) {
    const { labelsAsPlaceholders, formStore, wideSubmitButtons,
      buttonForegroundColor, buttonBackgroundColor, fontFamily, labelFontSize,
      labelFontWeight, labelColor, borderRadius, borderWidth, borderColor, inputBackgroundColor } = this.props
    const { formSettings } = formStore
    const { firstName, lastName, companyName, email, phone, eventDate,
      eventTime, eventLength, venueName, venueStreet, venueCity, venueState,
      venueZip, customerStreet, customerCity, customerState, customerZip,
      estimatedBudget, estimatedNumberGuests, leadSource, eventType,
      indoorOutdoor, stairSetup, serviceId, packageId, employeeId,
      subject, message, contactPreference, customQuestionValues
    } = formStore
    const { errors, loading } = this.state
    const { host, apiKey } = formStore.widgetStore

    const buttonStyle = {
      ...styles.submitButton,
      backgroundColor: buttonBackgroundColor ? buttonBackgroundColor : formSettings.primaryColor,
      borderColor: buttonBackgroundColor ? buttonBackgroundColor : formSettings.primaryColor,
      color: buttonForegroundColor ? buttonForegroundColor : formSettings.contrastColor,
      fontFamily: fontFamily ? fontFamily : null
    }

    const labelStyle = {
      fontFamily: fontFamily ? fontFamily : null,
      fontWeight: labelFontWeight ? labelFontWeight : null,
      fontSize: labelFontSize ? labelFontSize : null,
      color: labelColor ? labelColor : null
    }

    const inputWrapperStyle = {
      fontFamily: fontFamily ? fontFamily : null
    }

    const inputStyle = {
      borderRadius: borderRadius ? borderRadius : null,
      borderWidth: borderWidth ? borderWidth : null,
      borderColor: borderColor ? borderColor : null,
      backgroundColor: inputBackgroundColor ? inputBackgroundColor : null
    }

    if(!formSettings[`${camelCase(field)}Field`] && !formSettings.customQuestions.find(customQuestion => customQuestion.columnName == field)) {
      // console.log(`Skipping ${field}: ${camelCase(field)}Field is not enabled in form settings`)
      return null
    }

    switch(field) {
      case "first_name":
        return (
          <TextField
            name="firstName"
            key={field}
            label={formSettings.firstNameFieldLabel || "First Name"}
            value={firstName}
            onChange={val => (formStore.firstName = val)}
            errors={errors}
            required={formSettings.firstNameField == "required"}
            style={{...styles.inputField, ...inputWrapperStyle, width: `${formSettings.firstNameFieldWidth}%`}}
            inputStyle={inputStyle}
            labelStyle={labelStyle}
            labelsAsPlaceholders={labelsAsPlaceholders}
          />
        )
      case "last_name":
        return (
          <TextField
            name="lastName"
            key={field}
            label={formSettings.lastNameFieldLabel || "Last Name"}
            value={lastName}
            onChange={val => (formStore.lastName = val)}
            errors={errors}
            required={formSettings.lastNameField == "required"}
            style={{...styles.inputField, ...inputWrapperStyle, width: `${formSettings.lastNameFieldWidth}%`}}
            inputStyle={inputStyle}
            labelStyle={labelStyle}
            labelsAsPlaceholders={labelsAsPlaceholders}
          />
        )
      case "company_name":
        return (
          <TextField
            name="companyName"
            key={field}
            label={formSettings.companyNameFieldLabel || "Company Name"}
            value={companyName}
            onChange={val => (formStore.companyName = val)}
            errors={errors}
            required={formSettings.companyNameField == "required"}
            style={{...styles.inputField, ...inputWrapperStyle, width: `${formSettings.companyNameFieldWidth}%`}}
            inputStyle={inputStyle}
            labelStyle={labelStyle}
            labelsAsPlaceholders={labelsAsPlaceholders}
          />
        )
      case "email":
        return (
          <TextField
            name="email"
            key={field}
            label={formSettings.emailFieldLabel || "Email"}
            value={email}
            onChange={val => (formStore.email = val)}
            errors={errors}
            required={formSettings.emailField == "required"}
            style={{...styles.inputField, ...inputWrapperStyle, width: `${formSettings.emailFieldWidth}%`}}
            labelStyle={labelStyle}
            inputStyle={inputStyle}
            labelsAsPlaceholders={labelsAsPlaceholders}
          />
        )
      case "phone":
        return (
          <TextField
            name="phone"
            key={field}
            label={formSettings.phoneFieldLabel || "Phone"}
            hint={formSettings.smsDisclaimerText}
            value={phone}
            onChange={val => (formStore.phone = val)}
            errors={errors}
            required={formSettings.phoneField == "required"}
            style={{...styles.inputField, ...inputWrapperStyle, width: `${formSettings.phoneFieldWidth}%`}}
            labelStyle={labelStyle}
            inputStyle={inputStyle}
            labelsAsPlaceholders={labelsAsPlaceholders}
          />
        )
      case "event_date":
        return (
          <DateField
            name="eventDate"
            key={field}
            label={formSettings.eventDateFieldLabel || "Event Date"}
            value={eventDate}
            onChange={(date) => (formStore.eventDate = date)}
            errors={errors}
            dateFormat={formSettings.dateFormat}
            required={formSettings.eventDateField == "required"}
            style={{...styles.inputField, ...inputWrapperStyle, width: `${formSettings.eventDateFieldWidth}%`}}
            labelStyle={labelStyle}
            inputStyle={inputStyle}
            labelsAsPlaceholders={labelsAsPlaceholders}
            minDate={new Date()}
          />
        )
      case "event_time":
        return (
          <TimeField
            name="eventTime"
            key={field}
            label={formSettings.eventTimeFieldLabel || "Event Time"}
            value={eventTime}
            onChange={(time) => (formStore.eventTime = time)}
            errors={errors}
            timeFormat={formSettings.timeFormat}
            required={formSettings.eventTimeField == "required"}
            style={{...styles.inputField, ...inputWrapperStyle, width: `${formSettings.eventTimeFieldWidth}%`}}
            labelStyle={labelStyle}
            inputStyle={inputStyle}
            labelsAsPlaceholders={labelsAsPlaceholders}
          />
        )
      case "event_length":
        return (
          <TextField
            name="eventLength"
            key={field}
            label={formSettings.eventLengthFieldLabel || "Hours Needed"}
            value={eventLength}
            onChange={val => (formStore.eventLength = val)}
            errors={errors}
            required={formSettings.eventLengthField == "required"}
            style={{...styles.inputField, ...inputWrapperStyle, width: `${formSettings.eventLengthFieldWidth}%`}}
            labelStyle={labelStyle}
            inputStyle={inputStyle}
            labelsAsPlaceholders={labelsAsPlaceholders}
          />
        )
      case "venue_name":
        return (
          <TextField
            name="venueName"
            key={field}
            label={formSettings.venueNameFieldLabel || "Venue Name"}
            value={venueName}
            onChange={val => (formStore.venueName = val)}
            errors={errors}
            required={formSettings.venueNameField == "required"}
            style={{...styles.inputField, ...inputWrapperStyle, width: `${formSettings.venueNameFieldWidth}%`}}
            labelStyle={labelStyle}
            inputStyle={inputStyle}
            labelsAsPlaceholders={labelsAsPlaceholders}
          />
        )
      case "venue_address":
        return (
          <div key={field} style={{...styles.addressWrapper, ...inputWrapperStyle, width: `${formSettings.venueAddressFieldWidth}%`}}>
            <TextField
              name="venueStreet"
              label={formSettings.venueAddressFieldLabel ? `${formSettings.venueAddressFieldLabel}: Street` : "Venue Address: Street"}
              value={venueStreet}
              onChange={val => (formStore.venueStreet = val)}
              errors={errors}
              required={formSettings.venueAddressField == "required"}
              style={styles.inputField}
              labelStyle={labelStyle}
              inputStyle={inputStyle}
              labelsAsPlaceholders={labelsAsPlaceholders}
            />
            <div style={styles.addressLine2Wrapper}>
              <TextField
                name="venueCity"
                label="City"
                value={venueCity}
                onChange={val => (formStore.venueCity = val)}
                errors={errors}
                required={formSettings.venueAddressField == "required"}
                style={{...styles.inputField, ...styles.cityField}}
                labelStyle={labelStyle}
                inputStyle={inputStyle}
                labelsAsPlaceholders={labelsAsPlaceholders}
              />
              { (formSettings.stateCodes && formSettings.stateCodes.length > 0) &&
              <SelectField
                name="venueState"
                label={formSettings.stateName}
                value={venueState}
                options={formSettings.stateCodes.slice()}
                onChange={val => (formStore.venueState = val)}
                errors={errors}
                required={formSettings.venueAddressField == "required"}
                style={{...styles.inputField, ...styles.zipField}}
                labelStyle={labelStyle}
                inputStyle={inputStyle}
                labelsAsPlaceholders={labelsAsPlaceholders}
              />
              }
              <TextField
                name="venueZip"
                label={formSettings.zipName}
                value={venueZip}
                onChange={val => (formStore.venueZip = val)}
                errors={errors}
                required={formSettings.venueAddressField == "required"}
                style={{...styles.inputField, ...styles.zipField}}
                labelStyle={labelStyle}
                inputStyle={inputStyle}
                labelsAsPlaceholders={labelsAsPlaceholders}
              />
            </div>
          </div>
        )
      case "customer_address":
        return (
          <div key={field} style={{...styles.addressWrapper, ...inputWrapperStyle, width: `${formSettings.customerAddressFieldWidth}%`}}>
            <TextField
              name="customerStreet"
              label={formSettings.customerAddressFieldLabel ? `${formSettings.customerAddressFieldLabel}: Street` : "Customer Address: Street"}
              value={customerStreet}
              onChange={val => (formStore.customerStreet = val)}
              errors={errors}
              required={formSettings.customerAddressField == "required"}
              style={styles.inputField}
              labelStyle={labelStyle}
              inputStyle={inputStyle}
              labelsAsPlaceholders={labelsAsPlaceholders}
            />
            <div style={styles.addressLine2Wrapper}>
              <TextField
                name="customerCity"
                label="City"
                value={customerCity}
                onChange={val => (formStore.customerCity = val)}
                errors={errors}
                required={formSettings.customerAddressField == "required"}
                style={{...styles.inputField, ...styles.cityField}}
                labelStyle={labelStyle}
                inputStyle={inputStyle}
                labelsAsPlaceholders={labelsAsPlaceholders}
              />
              { (formSettings.stateCodes && formSettings.stateCodes.length > 0) &&
              <SelectField
                name="customerState"
                label={formSettings.stateName}
                value={customerState}
                options={formSettings.stateCodes.slice()}
                onChange={val => (formStore.customerState = val)}
                errors={errors}
                required={formSettings.customerAddressField == "required"}
                style={{...styles.inputField, ...styles.zipField}}
                labelStyle={labelStyle}
                inputStyle={inputStyle}
                labelsAsPlaceholders={labelsAsPlaceholders}
              />
              }
              <TextField
                name="customerZip"
                label={formSettings.zipName}
                value={customerZip}
                onChange={val => (formStore.customerZip = val)}
                errors={errors}
                required={formSettings.customerAddressField == "required"}
                style={{...styles.inputField, ...styles.zipField}}
                labelStyle={labelStyle}
                inputStyle={inputStyle}
                labelsAsPlaceholders={labelsAsPlaceholders}
              />
            </div>
          </div>
        )
      case "estimated_budget":
        return (
          <TextField
            name="estimatedBudget"
            key={field}
            label={formSettings.estimatedBudgetFieldLabel || "Estimated Budget"}
            value={estimatedBudget}
            onChange={val => (formStore.estimatedBudget = val)}
            errors={errors}
            required={formSettings.estimatedBudgetField == "required"}
            style={{...styles.inputField, ...inputWrapperStyle, width: `${formSettings.estimatedBudgetFieldWidth}%`}}
            labelStyle={labelStyle}
            inputStyle={inputStyle}
            labelsAsPlaceholders={labelsAsPlaceholders}
          />
        )
      case "event_type":
        return (
          <SelectField
            name="eventType"
            key={field}
            label={formSettings.eventTypeFieldLabel || "Event Type"}
            value={eventType}
            options={formSettings.leadEventTypes.slice()}
            onChange={val => (formStore.eventType = val)}
            errors={errors}
            required={formSettings.eventTypeField === "required"}
            style={{...styles.inputField, ...inputWrapperStyle, width: `${formSettings.eventTypeFieldWidth}%`}}
            labelStyle={labelStyle}
            inputStyle={inputStyle}
            labelsAsPlaceholders={labelsAsPlaceholders}
          />
        )
      case "service_id":
        return (
          <SelectField
            name="serviceId"
            key={field}
            label={formSettings.serviceIdFieldLabel || "Service"}
            value={serviceId}
            options={formSettings.availableServices.map(service => [service.id, service.name])}
            onChange={val => (formStore.serviceId = val)}
            errors={errors}
            required={formSettings.serviceIdField === "required"}
            style={{...styles.inputField, ...inputWrapperStyle, width: `${formSettings.serviceIdFieldWidth}%`}}
            labelStyle={labelStyle}
            inputStyle={inputStyle}
            labelsAsPlaceholders={labelsAsPlaceholders}
          />
        )
      case "package_id":
        return (
          <GroupedSelectField
            name="packageId"
            key={field}
            label={formSettings.packageIdFieldLabel || "Package"}
            value={packageId}
            optionGroups={formSettings.availablePackages.slice()}
            onChange={val => (formStore.packageId = val)}
            errors={errors}
            required={formSettings.packageIdField === "required"}
            style={{...styles.inputField, ...inputWrapperStyle, width: `${formSettings.packageIdFieldWidth}%`}}
            labelStyle={labelStyle}
            inputStyle={inputStyle}
            labelsAsPlaceholders={labelsAsPlaceholders}
          />
        )
      case "employee_id":
        return (
          <SelectField
            name="employeeId"
            key={field}
            label={formSettings.employeeIdFieldLabel || "Staff"}
            value={employeeId}
            options={formSettings.employees.slice()}
            onChange={val => (formStore.employeeId = val)}
            errors={errors}
            required={formSettings.employeeIdField === "required"}
            style={{...styles.inputField, ...inputWrapperStyle, width: `${formSettings.employeeIdFieldWidth}%`}}
            labelStyle={labelStyle}
            inputStyle={inputStyle}
            labelsAsPlaceholders={labelsAsPlaceholders}
          />
        )
      case "estimated_number_guests":
        return (
          <TextField
            name="estimatedNumberGuests"
            key={field}
            label={formSettings.estimatedNumberGuestsFieldLabel || "Estimated Number of Guests"}
            value={estimatedNumberGuests}
            onChange={val => (formStore.estimatedNumberGuests = val)}
            errors={errors}
            required={formSettings.estimatedNumberGuestsField == "required"}
            style={{...styles.inputField, ...inputWrapperStyle, width: `${formSettings.estimatedNumberGuestsFieldWidth}%`}}
            labelStyle={labelStyle}
            inputStyle={inputStyle}
            labelsAsPlaceholders={labelsAsPlaceholders}
          />
        )
      case "indoor_outdoor":
        return (
          <SelectField
            name="indoorOutdoor"
            key={field}
            label={formSettings.indoorOutdoorFieldLabel || "Is this booking indoors or outdoors?"}
            value={indoorOutdoor}
            options={formSettings.indoorOutdoorOptions.slice()}
            onChange={val => (formStore.indoorOutdoor = val)}
            errors={errors}
            required={formSettings.indoorOutdoorField === "required"}
            style={{...styles.inputField, ...inputWrapperStyle, width: `${formSettings.indoorOutdoorFieldWidth}%`}}
            labelStyle={labelStyle}
            inputStyle={inputStyle}
            labelsAsPlaceholders={labelsAsPlaceholders}
          />
        )
      case "stair_setup":
        return (
          <SelectField
            name="stairSetup"
            key={field}
            label={formSettings.stairSetupFieldLabel || "Will we need to carry equipment up stairs?"}
            value={stairSetup}
            options={formSettings.stairSetupOptions.slice()}
            onChange={val => (formStore.stairSetup = val)}
            errors={errors}
            required={formSettings.stairSetupField === "required"}
            style={{...styles.inputField, ...inputWrapperStyle, width: `${formSettings.stairSetupFieldWidth}%`}}
            labelStyle={labelStyle}
            inputStyle={inputStyle}
            labelsAsPlaceholders={labelsAsPlaceholders}
          />
        )
      case "contact_preference":
        return (
          <SelectField
            name="contactPreference"
            key={field}
            label={formSettings.contactPreferenceFieldLabel || "Contact Preference"}
            value={contactPreference}
            options={formSettings.contactPreferenceOptions.slice()}
            onChange={val => (formStore.contactPreference = val)}
            errors={errors}
            required={formSettings.contactPreferenceField === "required"}
            style={{...styles.inputField, ...inputWrapperStyle, width: `${formSettings.contactPreferenceFieldWidth}%`}}
            labelStyle={labelStyle}
            inputStyle={inputStyle}
            labelsAsPlaceholders={labelsAsPlaceholders}
          />
        )
      case "lead_source":
        return (
          <SelectField
            name="leadSource"
            key={field}
            label={formSettings.leadSourceFieldLabel || "How did you find us?"}
            value={leadSource}
            options={formSettings.leadSources.slice()}
            onChange={val => (formStore.leadSource = val)}
            errors={errors}
            required={formSettings.leadSourceField === "required"}
            style={{...styles.inputField, ...inputWrapperStyle, width: `${formSettings.leadSourceFieldWidth}%`}}
            labelStyle={labelStyle}
            inputStyle={inputStyle}
            labelsAsPlaceholders={labelsAsPlaceholders}
          />
        )
      case "subject":
        return (
          <TextField
            name="subject"
            key={field}
            label={formSettings.subjectFieldLabel || "Subject"}
            value={subject}
            onChange={val => (formStore.subject = val)}
            errors={errors}
            required={formSettings.subjectField == "required"}
            style={{...styles.inputField, ...inputWrapperStyle, width: `${formSettings.subjectFieldWidth}%`}}
            labelStyle={labelStyle}
            inputStyle={inputStyle}
            labelsAsPlaceholders={labelsAsPlaceholders}
          />
        )
      case "message":
        return (
          <TextAreaField
            name="message"
            key={field}
            label={formSettings.messageFieldLabel || "Message"}
            value={message}
            onChange={val => (formStore.message = val)}
            errors={errors}
            required={formSettings.messageField == "required"}
            style={{...styles.inputField, ...inputWrapperStyle, width: `${formSettings.messageFieldWidth}%`}}
            labelStyle={labelStyle}
            inputStyle={inputStyle}
            labelsAsPlaceholders={labelsAsPlaceholders}
          />
        )
      default:
        const customQuestion = formSettings.customQuestions.find(customQuestion => customQuestion.columnName == field)
        console.log({ customQuestion })
        if(!customQuestion) return null

        return (
          <CustomField
            key={customQuestion.columnName}
            apiKey={apiKey}
            host={host}
            kind={customQuestion.kind}
            name={customQuestion.columnName}
            label={customQuestion.label}
            options={customQuestion.options}
            hint={customQuestion.hint}
            value={formStore.customValueFor(customQuestion.columnName)}
            onChange={val => formStore.setCustomValueFor(customQuestion.columnName, val)}
            errors={errors}
            required={customQuestion.status == "required"}
            style={{...styles.inputField, ...inputWrapperStyle, width: `${customQuestion.width}%`}}
            labelStyle={labelStyle}
            inputStyle={inputStyle}
            labelsAsPlaceholders={labelsAsPlaceholders}
            stateCodes={formSettings.stateCodes}
            stateName={formSettings.stateName}
            zipName={formSettings.zipName}
          />
        )
    }
  }


  render() {
    const { labelsAsPlaceholders, formStore, wideSubmitButtons,
      buttonForegroundColor, buttonBackgroundColor, fontFamily, labelFontSize,
      labelFontWeight, labelColor, borderRadius, borderWidth, borderColor, inputBackgroundColor } = this.props
    const { formSettings } = formStore
    const { firstName, lastName, companyName, email, phone, eventDate,
      eventTime, eventLength, venueName, venueStreet, venueCity, venueState,
      venueZip, customerStreet, customerCity, customerState, customerZip,
      estimatedBudget, estimatedNumberGuests, leadSource, eventType,
      indoorOutdoor, stairSetup, serviceId, packageId, employeeId,
      subject, message, contactPreference, customQuestionValues,
      fullName
    } = formStore
    const { errors, loading } = this.state

    const buttonStyle = {
      ...styles.submitButton,
      backgroundColor: buttonBackgroundColor ? buttonBackgroundColor : formSettings.primaryColor,
      borderColor: buttonBackgroundColor ? buttonBackgroundColor : formSettings.primaryColor,
      color: buttonForegroundColor ? buttonForegroundColor : formSettings.contrastColor,
      fontFamily: fontFamily ? fontFamily : null
    }

    const labelStyle = {
      fontFamily: fontFamily ? fontFamily : null,
      fontWeight: labelFontWeight ? labelFontWeight : null,
      fontSize: labelFontSize ? labelFontSize : null,
      color: labelColor ? labelColor : null
    }

    const inputWrapperStyle = {
      fontFamily: fontFamily ? fontFamily : null
    }

    const inputStyle = {
      borderRadius: borderRadius ? borderRadius : null,
      borderWidth: borderWidth ? borderWidth : null,
      borderColor: borderColor ? borderColor : null,
      backgroundColor: inputBackgroundColor ? inputBackgroundColor : null
    }

    // console.log({ formStore, formSettings: toJS(formSettings) })

    return(
      <form
        onSubmit={this.handleSubmission}
        ref={ref => this.formRef = ref}
      >
        <div>
          <FlashHandler errors={errors} />
          <HoneypotField
            value={fullName}
            onChange={val => (formStore.fullName = val)}
            style={styles.inputField}
          />
          <div style={styles.fieldWrapper}>
            {formSettings.mergedSortableFields.map(field => this.renderField(field))}
          </div>
          <div style={styles.fieldWrapper}>
            <SubmitField
              style={buttonStyle}
              disabled={loading}
              value={ loading ? "Submitting..." : formSettings.submitButtonText }
              block={wideSubmitButtons}
            />
          </div>
        </div>
      </form>
    )
  }
}

const styles = {
  fieldWrapper: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap"
  },
  inputField: {
    boxSizing: "border-box",
    paddingLeft: 10,
    paddingRight: 10,
    width: "100%",
    marginBottom: 10
  },
  addressLine2Wrapper: {
    display: "flex",
    flexDirection: "row"
  },
  cityField: {
    flex: 5,
    paddingRight: 5
  },
  zipField: {
    flex: 4,
    paddingLeft: 5
  },
  stateField: {
    flex: 3,
    paddingLeft: 5,
    paddingRight: 5
  },
  submitButton: {
    margin: 10
  }
}
