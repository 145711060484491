import '@babel/polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import dasherize from 'dasherize'
import { subscribeToDynamicContentChanges } from '../helpers/dynamic-content'

import AddOnDropdown from '../components/AddOnDropdown'
import AdminBackdropBrowser from '../components/AdminBackdropBrowser'
import ActivityForDate from '../components/ActivityForDate'
import AvailabilityChecker from '../components/AvailabilityChecker'
import BackdropBrowser from '../components/BackdropBrowser'
import BackdropDropdown from '../components/BackdropDropdown'
import Checklist from '../components/Checklist'
import ContactForm from '../components/ContactForm'
import EmbedButton from '../components/EmbedButton'
import EventDropdown from '../components/EventDropdown'
import EventTypeSelector from '../components/EventTypeSelector'
import FormBuilder from '../components/FormBuilder'
import FranchiseCalendar from '../components/FranchiseCalendar'
import GlobalSearch from '../components/GlobalSearch'
import LeadComparisonChart from '../components/LeadComparisonChart'
import ProposalComparisonChart from '../components/ProposalComparisonChart'
import MediaLibraryBrowser from '../components/MediaLibraryBrowser'
import MiniSessionSlotPicker from '../components/MiniSessionSlotPicker'
import PackageEditor from '../components/PackageEditor'
import PackageSelector from '../components/PackageSelector'
import PaymentForm from '../components/PaymentForm'
import RevenueGrowthChart from '../components/RevenueGrowthChart'
import RevenueSnapshotChart from '../components/RevenueSnapshotChart'
import StaffForDate from '../components/StaffForDate'
import StripeEmbeddedConnect from '../components/StripeEmbeddedConnect'
import StripeEmbeddedOnboarding from '../components/StripeEmbeddedOnboarding'
import SuggestionListEditor from '../components/SuggestionListEditor'
import TaxSimulator from '../components/TaxSimulator'
import TravelSimulator from '../components/TravelSimulator'
import UserSelector from '../components/UserSelector'
import VenueDropdown from '../components/VenueDropdown'
import "react-big-calendar/lib/css/react-big-calendar.css"
import iframeResizerContentWindow from 'iframe-resizer'
import "../helpers/quill"
import "../helpers/tempus_dominus"

const components = {
  "react-add-on-dropdown": AddOnDropdown,
  "react-admin-backdrop-browser": AdminBackdropBrowser,
  "react-activity-for-date": ActivityForDate,
  "react-availability-checker": AvailabilityChecker,
  "react-backdrop-browser": BackdropBrowser,
  "react-backdrop-dropdown": BackdropDropdown,
  "react-event-dropdown": EventDropdown,
  "react-global-search": GlobalSearch,
  "react-checklist": Checklist,
  "react-contact-form": ContactForm,
  "react-revenue-growth-chart": RevenueGrowthChart,
  "react-lead-comparison-chart": LeadComparisonChart,
  "react-proposal-comparison-chart": ProposalComparisonChart,
  "react-revenue-snapshot-chart": RevenueSnapshotChart,
  "react-embed-button": EmbedButton,
  "react-form-builder": FormBuilder,
  "react-franchise-calendar": FranchiseCalendar,
  "react-media-library-browser": MediaLibraryBrowser,
  "react-mini-session-slot-picker": MiniSessionSlotPicker,
  "react-package-editor": PackageEditor,
  "react-package-selector": PackageSelector,
  "react-payment-form": PaymentForm,
  "react-stripe-embedded-connect": StripeEmbeddedConnect,
  "react-stripe-embedded-onboarding": StripeEmbeddedOnboarding,
  "react-suggestion-list-editor": SuggestionListEditor,
  "react-staff-for-date": StaffForDate,
  "react-tax-simulator": TaxSimulator,
  "react-travel-simulator": TravelSimulator,
  "react-user-selector": UserSelector,
  "react-venue-dropdown": VenueDropdown,
}

const loadReactComponents = () => {
  Object.entries(components).forEach(entry =>  {
    const className = entry[0]
    const component = entry[1]
    const nodes = Array.from(document.getElementsByClassName(className))
    nodes.forEach(node => {
      if(!node.classList.contains("react-component-loaded")) {
        const data = JSON.parse(node.getAttribute('data-props'))
        ReactDOM.render(React.createElement(component, data), node)
        node.classList.add("react-component-loaded")
      }
    })
  })
}

document.addEventListener('DOMContentLoaded', loadReactComponents)
subscribeToDynamicContentChanges(loadReactComponents)

// TODO: Migrate these to the new format above
document.addEventListener('DOMContentLoaded', () => {
  let nodes

  nodes = document.getElementsByClassName('react-event-type-selector')
  for(let i = 0; i < nodes.length; i++) {
    const node = nodes[i]
    const data = JSON.parse(node.getAttribute('data'))

    ReactDOM.render(<EventTypeSelector {...data} />, node)
  }
})
