import React from 'react'
import PropTypes from 'prop-types'
import { Form, FormGroup } from 'react-bootstrap'
import ReactDatePicker from 'react-datepicker'
import {observer} from "mobx-react"
import store from '../stores/store.js'
import { parse, format } from 'date-fns'

const parseDate = (dateString, dateFormat) => {
  if(!dateString) {
    return null
  }

  const parsedDate = parse(dateString, dateFormat, new Date())
  if(parsedDate == "Invalid Date") {
    return null
  }

  return parsedDate
}

const formatDate = (date, dateFormat) => {
  const formatted = format(date, dateFormat)
  return formatted
}

class Input extends React.Component {
  render() {
    const { onClick, value, style, onChange, placeholder, disabled } = this.props
    return (
      <input
        type="text"
        onClick={onClick}
        value={value}
        onChange={onChange}
        style={style}
        disabled={disabled}
        className="form-control"
        placeholder={placeholder}
      />
    )
  }
}

@observer
export default class DateField extends React.Component {
  render() {
    const { field } = this.props
    const { name, placeholder, uuid, hint, required } = field
    const instance = this.props.instance || 0
    const value = store.valueFor(uuid, "text", instance)
    const errorMessage = store.errorFor(uuid, "text", instance)
    const dateFormat = store.dateFormat

    return (
      <FormGroup>
        <Form.Label>{ required ? "*" : "" } {name}</Form.Label>
        <ReactDatePicker
          name={name}
          customInput={<Input  />}
          onChange={startDate => store.setValueFor(uuid, "text", instance, formatDate(startDate, dateFormat))}
          selected={parseDate(value, dateFormat)}
          placeholderText={placeholder}
          dateFormat={dateFormat}
          disabled={!store.editable}
          isInvalid={!!errorMessage}
        />
        { errorMessage && <Form.Control.Feedback type="invalid" style={{display: "block"}}>{errorMessage}</Form.Control.Feedback> }
        { hint && <Form.Text muted>{hint}</Form.Text > }
      </FormGroup>
    )
  }
}
