import React from 'react'
import PropTypes from 'prop-types'
import { FormGroup, FormControl } from 'react-bootstrap'
import { observe, toJS } from 'mobx'
import { observer, inject } from 'mobx-react'
import ErrorBlock, { validationStateFor } from './ErrorBlock'

const DELAY = 400

@inject("store")
@inject("formErrors")
@observer
export default class UnitSelector extends React.Component {
  static propTypes = {
    store: PropTypes.object.isRequired,
  }

  state = {
    units: this.props.store.units
  }

  componentDidMount() {
    const { store } = this.props

    observe(store, (change) => {
      this.setState({ units: store.units })
    })
  }

  handleChange = (e) => {
    const { store } = this.props
    const units = e.target.value

    this.setState({ units }, () => {
      if(this.timer) {
        clearTimeout(this.timer)
      }

      this.timer = setTimeout(() => { store.setUnits(units) }, DELAY)
    })
  }

  componentWillUnmount() {
    if(this.timer) {
      this.clearTimeout(this.timer)
    }
  }

  render() {
    const { store, formErrors } = this.props
    const { units } = this.state
    const { unitsHowMany } = store.selectedPackage.attributes

    return (
      <FormGroup className="unit-selector">
        <div className="unit-selector-label">{ unitsHowMany }</div>
        <FormControl
          as="input"
          type="number"
          name="unit-count"
          value={units}
          onChange={this.handleChange}
          isInvalid={formErrors.units}
        />
        <ErrorBlock error={formErrors.units} />
      </FormGroup>
    )
  }
}

