import React from 'react';
import PropTypes from 'prop-types'
import AsyncSelect from 'react-select/lib/Async'
// https://react-select.com/async#loading-asynchronously
import client from '../../helpers/client'
import CustomOption from './CustomOption'

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    borderRadius: 0,
    fontSize: 18,
    paddingLeft: 10
  })
}

const deserializeUser = (user) => {
  if(user && user.id && user.attributes) {
    return {
      value: user.id,
      label: user.attributes.fullName,
      attributes: user.attributes
    }
  }
  else {
    return null
  }
}

const deserializeUsers = (serializedUsers) => {
  if(Array.isArray(serializedUsers)) {
    return serializedUsers.map(user => deserializeUser(user)).filter(user => user)
  }
  else {
    return deserializeUser(serializedUsers)
  }
}



class UserSelector extends React.Component {
  static propTypes = {
    role: PropTypes.oneOf(['customer', 'staff', 'admin']),
    users: PropTypes.array,
    inputId: PropTypes.string,
    selected: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.arrayOf(PropTypes.object),
    ]),
    clearable: PropTypes.bool,
    apiKey: PropTypes.string.isRequired,
    checkAvailabilityForEvent: PropTypes.number,
    multiple: PropTypes.bool,
    onChange: PropTypes.func
  }
  
  static defaultProps = {
    clearable: false,
    multiple: false,
    users: []
  }

  state = {
    selectedOptions: deserializeUsers(this.props.selected),
    currentValue: ""
  }

  componentDidMount() {
    const { inputId } = this.props

    if(inputId) {
      this.input = document.getElementById(inputId)
      this.syncHtmlInput()
    }
  }

  handleInputChange = (currentValue) => {
    this.setState({ currentValue })
  }

  handleChange = (selectedOptions) => {
    const { onChange } = this.props

    this.setState({ selectedOptions }, () => this.syncHtmlInput())
    if(onChange) {
      onChange(selectedOptions)
    }
  }

  syncHtmlInput() {
    const { selectedOptions } = this.state

    if(this.input) {
      if(selectedOptions) {
        if(Array.isArray(selectedOptions)) {
          this.input.value = selectedOptions.map(option => option.value).join(",")
        }
        else {
          this.input.value = selectedOptions.value
        }
      }
      else {
        this.input.value = ""
      }
    }
  }

  handleLoadOptions = (inputValue, callback) => {
    const { role, apiKey, checkAvailabilityForEvent } = this.props
    const { currentValue } = this.state

    const url = checkAvailabilityForEvent ?
      `/api/v1/users/availability.json?api_key=${apiKey}&text=${currentValue}&event_id=${checkAvailabilityForEvent}`
      : 
      `/api/v1/users.json?api_key=${apiKey}&text=${currentValue}&role=${role}`


    client.get(url)
      .then(response => {
        const options = deserializeUsers(response.data.data)
        callback(options)
      })
      .catch(error => {
        const { data, status } = error.response
        const { errors } = data
        const errorMessage = status === 422 ? errors.join(", ") : "Error contacting server"
        alert(errorMessage)
      })
  }



  render() {
    const { users, clearable, checkAvailabilityForEvent, multiple } = this.props
    const { selectedOptions, currentValue } = this.state;

    return (
      <AsyncSelect
        cacheOptions
        name={"user_id"}
        loadOptions={this.handleLoadOptions}
        components={{ Option: CustomOption }}
        onInputChange={this.handleInputChange}
        onChange={this.handleChange}
        styles={customStyles}
        placeholder="Start typing..."
        noOptionsMessage={() => currentValue ? "Unable to find any matching users" : "Start typing to search for users"}
        isClearable={clearable}
        defaultOptions={deserializeUsers(users)}
        value={selectedOptions}
        checkAvailabilityForEvent={checkAvailabilityForEvent}
        isMulti={multiple}
      />
    );
  }
}

export default UserSelector
