import React from 'react'
import { Carousel, Ratio } from 'react-bootstrap'

const SliderSlide = ({ slide }) => {
  switch(slide.type) {
    case "image":
      return (
        <Ratio aspectRatio="16x9">
          <img src={slide.url} className="img-fluid" />
        </Ratio>
      )
    case "video":
      return (
        <Ratio aspectRatio="16x9">
          <iframe
            allowfullscreen="0" 
            frameborder="0"
            src={`//www.youtube.com/embed/${slide.videoId}?rel=0&controls=1&showinfo=0`}
          />
        </Ratio>
      )
    default:
      return null
  }
}

const Slider = ({ slides }) => {
  if(slides.length === 0) {
    return null
  }
  else if(slides.length === 1) {
    return <SliderSlide slide={slides[0]} />
  }
  else {
    return (
      <Carousel>
        { slides.map(slide =>
        <Carousel.Item key={slide.url}>
          <SliderSlide slide={slide} />
        </Carousel.Item>
        )}
      </Carousel>
    )
  }
}

export default Slider
