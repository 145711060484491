import React from 'react'

const ShowAllPaymentMethods = ({ onClick, showBackToAllPaymentMethods }) => {
  if(!showBackToAllPaymentMethods) {
    return null
  }

  return (
    <div className="go-back">
      <a onClick={onClick}><i className="fa fa-chevron-left" /> Show all payment methods</a>
    </div>
  )
}

export default ShowAllPaymentMethods
