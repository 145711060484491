import React, { useState } from 'react';

const SavePaymentMethod = ({ savePaymentMethod, setSavePaymentMethod, savePaymentMethodMode }) => {
  switch(savePaymentMethodMode) {
    case 'required':
      return (
        <div className="save-payment-method-wrapper">
          <div style={styles.savedForFuture}>This payment method will be saved for future use</div>
        </div>
      )
    case 'optional':
      return (
        <div className="save-payment-method-wrapper">
          <label>
            <input
              type="checkbox"
              checked={savePaymentMethod}
              onChange={() => setSavePaymentMethod(!savePaymentMethod)}
            />
            <span>Save this payment method for future use</span>
          </label>
        </div>
      )
    default:
      return null
  }
}

const styles = {
  savedForFuture: {
    textAlign: 'center',
    fontStyle: 'italic',
  }
}


export default SavePaymentMethod
