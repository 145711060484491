import React, { useState } from 'react';

import CardButton from './CardButton'
import ShowAllPaymentMethods from './ShowAllPaymentMethods'
import SavePaymentMethod from './SavePaymentMethod'
import client from '../../../helpers/client'
// import {
//   SquarePaymentForm,
//   ApplePayButton,
//   CreditCardCVVInput,
//   CreditCardExpirationDateInput,
//   CreditCardNumberInput,
//   CreditCardPostalCodeInput,
//   CreditCardSubmitButton,
//   GooglePayButton,
//   MasterpassButton,
// } from 'react-square-payment-form';
// import 'react-square-payment-form/lib/default.css';

import { ApplePay, GooglePay, CreditCard, PaymentForm } from 'react-square-web-payments-sdk'

function extractErrorMessage(error) {
  if(error && error.response && error.response.data && error.response.data.error) {
    return error.response.data.error
  }
  else {
    return error.message
  }
}

const CardForm = ({ cardErrorMessages, onGoBack, savePaymentMethod, setSavePaymentMethod, showBackToAllPaymentMethods, allowUserToSavePaymentMethod, paymentMethod }) => (
  <div>
    <CardButton onClick={onGoBack} />
    { (cardErrorMessages && cardErrorMessages.length > 0) &&
      <div className="sq-error-message card-errors">
        {cardErrorMessages.map(errorMessage => (
          <li key={`sq-error-${errorMessage}`}>{errorMessage}</li>
        ))}
      </div>
    }
    <fieldset className="sq-fieldset">
      <CreditCard
        includeInputLabels
        render={(Button) => (
          <>
            { allowUserToSavePaymentMethod &&
            <SavePaymentMethod
              savePaymentMethod={savePaymentMethod}
              setSavePaymentMethod={setSavePaymentMethod}
              savePaymentMethodMode={paymentMethod.savePaymentMethodMode}
            />
            }
            <Button style={styles.submitButton}>Submit Payment</Button>
          </>
        )}
      >
        Submit Payment
      </CreditCard>
    </fieldset>


    {/* <CreditCardSubmitButton>Submit Payment</CreditCardSubmitButton> */}

    <ShowAllPaymentMethods onClick={onGoBack} showBackToAllPaymentMethods={showBackToAllPaymentMethods} />
  </div>
)

const SquareForm = ({
  paymentMethod,
  activePaymentMethod,
  paymentIntentId,
  setActivePaymentMethod,
  amountCents,
  amountCurrency,
  countryCode,
  setLoading,
  chargeDescription,
  allowUserToSavePaymentMethod,
  showBackToAllPaymentMethods,
  show
}) => {
  const { squareApplicationId, squareLocationId, squareSandbox } = paymentMethod

  const [cardErrorMessages, setCardErrorMessages] = useState([]);
  const [paymentRequestErrorMessages, setPaymentRequestErrorMessages] = useState([]);
  const amountDollars = (amountCents / 100).toFixed(2)
  const { savePaymentMethodMode } = paymentMethod

  const [savePaymentMethod, setSavePaymentMethod] = useState(savePaymentMethodMode !== "none")
  console.log({ savePaymentMethod })


  function handleCardSubmission({ token, verifiedBuyer }) {
    setLoading(true)

    const isCardPayment = (paymentMethod && activePaymentMethod === paymentMethod.id) ? true : false

    // if (errors) {
    //   setCardErrorMessages(errors.map(error => error.message));
    //   setLoading(false)
    //   return;
    // }

    setCardErrorMessages([]);
    setPaymentRequestErrorMessages([]);

    const result = client.post(`/payment_intents/${paymentIntentId}/submit_square_payment`, {
      squareNonce: token,
      buyerVerificationToken: null,
      savePaymentMethod: (isCardPayment && allowUserToSavePaymentMethod && savePaymentMethod)
    }, { withCredentials: true })
      .then((result) => {
        window.location.reload()
      })
      .catch((e) => {
        setCardErrorMessages([extractErrorMessage(e)])
        setLoading(false)
      })
    // API.post('/payments', data: { nonce: nonce, buyerVerificationToken: buyerVerificationToken }) // implement this
  }

//   function handlePaymentRequestSubmission(errors, squareNonce, cardData, buyerVerificationToken) {
//     setLoading(true)

//     if (errors) {
//       setPaymentRequestErrorMessages(errors.map(error => error.message));
//       setLoading(false)
//       return;
//     }

//     setCardErrorMessages([]);
//     setPaymentRequestErrorMessages([]);

//     const result = client.post(`/payment_intents/${paymentIntentId}/submit_square_payment`, {
//       squareNonce,
//       buyerVerificationToken,
//       savePaymentMethod: false
//     }, { withCredentials: true })
//       .then((result) => {
//         window.location.reload()
//       })
//       .catch((e) => {
//         setPaymentRequestErrorMessages([extractErrorMessage(e)])
//         setLoading(false)
//       })
//     // API.post('/payments', data: { nonce: nonce, buyerVerificationToken: buyerVerificationToken }) // implement this
//   }

  function createPaymentRequest() {
    return {
      requestShippingAddress: false,
      requestBillingInfo: false,
      currencyCode: amountCurrency,
      countryCode: countryCode,
      total: {
        label: chargeDescription,
        amount: amountDollars,
        pending: false,
      },
      lineItems: [
        {
          label: chargeDescription,
          amount: amountDollars,
          pending: false,
        },
      ],
    };
  }

  function createVerificationDetails() {
    return {
      amount: amountDollars,
      currencyCode: amountCurrency,
      intent: 'CHARGE',
      billingContact: {
        country: countryCode,
      },
    };
  }

  const loadingView = <div className="sq-wallet-loading"></div>;
  // const unavailableApple = (
  //   <div className="sq-wallet-unavailable">Apple pay unavailable. Open safari on desktop or mobile to use.</div>
  // );
  // const unavailableGoogle = <div className="sq-wallet-unavailable">Google pay unavailable.</div>;
  // const unavailableMasterpass = <div className="sq-wallet-unavailable">Masterpass unavailable.</div>;
  const unavailableApple = null
  const unavailableGoogle = null
  const unavailableMasterpass = null

  if(!show) {
    return null
  }

  return (
    <div className="checkcherry-payment-widget-square-form">
      <PaymentForm
        sandbox={squareSandbox}
        applicationId={squareApplicationId}
        locationId={squareLocationId}
        cardTokenizeResponseReceived={handleCardSubmission}
        createPaymentRequest={createPaymentRequest}
        createVerificationDetails={createVerificationDetails}
      >
        { (paymentRequestErrorMessages && paymentRequestErrorMessages.length > 0) &&
        <div className="sq-error-message card-errors">
          {paymentRequestErrorMessages.map(errorMessage => (
            <li key={`sq-error-${errorMessage}`}>{errorMessage}</li>
          ))}
        </div>
        }

        { activePaymentMethod === paymentMethod.id ?
          <>
            <CardForm
              paymentMethod={paymentMethod}
              savePaymentMethod={savePaymentMethod}
              setSavePaymentMethod={setSavePaymentMethod}
              allowUserToSavePaymentMethod={allowUserToSavePaymentMethod}
              cardErrorMessages={cardErrorMessages}
              onGoBack={() => setActivePaymentMethod(null) }
              showBackToAllPaymentMethods={showBackToAllPaymentMethods}
            />
          </>
          :
          <>
            <ApplePay />
            <GooglePay buttonColor="black" />
            <CardButton onClick={() => setActivePaymentMethod(paymentMethod.id)} />
          </>
        }
      </PaymentForm>
    </div>
  );

//   return (
//     <div className="checkcherry-payment-widget-square-form">
//       { (!activePaymentMethod) &&
//         <SquarePaymentForm
//           sandbox={squareSandbox}
//           applicationId={squareApplicationId}
//           locationId={squareLocationId}
//           cardNonceResponseReceived={handlePaymentRequestSubmission}
//           createPaymentRequest={createPaymentRequest}
//         >
//           <>
//             { (paymentRequestErrorMessages && paymentRequestErrorMessages.length > 0) &&
//               <div className="sq-error-message card-errors">
//                 {paymentRequestErrorMessages.map(errorMessage => (
//                   <li key={`sq-error-${errorMessage}`}>{errorMessage}</li>
//                 ))}
//               </div>
//             }
//             <ApplePayButton loadingView={loadingView} unavailableView={unavailableApple} />
//             <GooglePayButton loadingView={loadingView} unavailableView={unavailableGoogle} />
//           </>
//         </SquarePaymentForm>
//       }

//       { activePaymentMethod === paymentMethod.id ?
//         <SquarePaymentForm
//           sandbox={squareSandbox}
//           applicationId={squareApplicationId}
//           locationId={squareLocationId}
//           cardNonceResponseReceived={handleCardSubmission}
//           createPaymentRequest={createPaymentRequest}
//           createVerificationDetails={createVerificationDetails}
//         >
//           <CardForm
//             savePaymentMethod={savePaymentMethod}
//             setSavePaymentMethod={setSavePaymentMethod}
//             allowUserToSavePaymentMethod={allowUserToSavePaymentMethod}
//             cardErrorMessages={cardErrorMessages}
//             onGoBack={() => setActivePaymentMethod(null) }
//           />
//         </SquarePaymentForm>
//         :
//         <CardButton onClick={() => setActivePaymentMethod(paymentMethod.id)} />
//       }
//     </div>
//   );
};

const styles = {
  submitButton: {
    backgroundColor: "#ffdc5d",
    color: "#000",
    borderRadius: 23,
    fontSize: 20,
    fontWeight: 700,
    fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
    margin: "20px auto 50px",
  }
}

export default SquareForm;
