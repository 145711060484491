import React, { useState, useEffect } from 'react'
import client from '../../../helpers/client'
import { loadStripe } from '@stripe/stripe-js';
import ShowAllPaymentMethods from './ShowAllPaymentMethods'
import SavePaymentMethod from './SavePaymentMethod'
import CardButton from './CardButton'
import {
  CardElement,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  PaymentRequestButtonElement,
  Elements,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: "16px",
      outline: "0",
      '::placeholder': {
        color: '#aab7c4'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  }
};

const CheckoutForm = ({ paymentMethod, paymentIntentId, activePaymentMethod, setActivePaymentMethod, amountCents, amountCurrency, countryCode, chargeDescription, loading, setLoading, allowUserToSavePaymentMethod, showBackToAllPaymentMethods, stripeClientSecret }) => {
  const [errorFields, setErrorFields] = useState({});
  const [errorMessage, setErrorMessage] = useState(null);
  const [completedFields, setCompletedFields] = useState({});
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState(null);
  const elements = useElements();
  const { savePaymentMethodMode } = paymentMethod
  const [savePaymentMethod, setSavePaymentMethod] = useState(savePaymentMethodMode !== "none")
  console.log({ savePaymentMethod })

  const handlePaymentRequestSubmission = async (event) => {
    const paymentMethodId = event.paymentMethod.id

    // Call to our server
    try {
      const result = await client.post(`/payment_intents/${paymentIntentId}/submit_stripe_payment`, {
        stripePaymentMethodId: paymentMethodId,
        savePaymentMethod: false
      }, { withCredentials: true })

      event.complete('success')
      handleServerResponse(result)
    }
    catch(e) {
      console.warn(e.message)
      event.complete('fail')
      handleServerResponse(e.response)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (stripe) {
      const paymentRequest = stripe.paymentRequest({
        country: countryCode,
        currency: amountCurrency.toLowerCase(),
        disableWallets: ["link"],
        total: {
          label: chargeDescription,
          amount: amountCents,
        },
        requestPayerName: false,
        requestPayerEmail: false,
      });
      
      paymentRequest.on('paymentmethod', handlePaymentRequestSubmission)
      // Callback when a payment method is created.
      // paymentRequest.on('paymentmethod', async (event) => {
      //   const paymentMethodId = event.paymentMethod.id
      //   console.log({ event })
      //   // Confirm the PaymentIntent with the payment method returned from the payment request.
      //   const {error} = await stripe.confirmCardPayment(
      //     stripeClientSecret,
      //     {
      //       payment_method: event.paymentMethod.id
      //     },
      //     {handleActions: false}
      //   );
      //   console.log('here1')
      //   if (error) {
      //     console.log({ error })
      //     debugger
      //     // Report to the browser that the payment failed.
      //     event.complete('fail');
      //     handlePayment({error});
      //   }
      //   else {
      //     console.log('success')
      //     // Report to the browser that the confirmation was successful, prompting
      //     // it to close the browser payment method collection interface.
      //     event.complete('success');
      //     // Let Stripe.js handle the rest of the payment flow, including 3D Secure if needed.
      //     const response = await stripe.confirmCardPayment(
      //       stripeClientSecret
      //     );
      //     console.log({ response })
      //     handlePayment(response);
      //   }
      // });

      // Check the availability of the Payment Request API.
      paymentRequest.canMakePayment().then(result => {
        if (result) {
          setPaymentRequest(paymentRequest);
        }
      });
    }
  }, [stripe]);


  // Handle real-time validation errors from the card Element.
  const handleChange = (event) => {
    if (event.error) {
      setErrorMessage(event.error.message);
      setCompletedFields({})
    } else {
      setErrorMessage(null);
    }

    setErrorFields(prevState => ({
      ...prevState,
      [event.elementType]: !!event.error
    }))

    setCompletedFields(prevState => ({
      ...prevState,
      [event.elementType]: event.complete
    }))
  }

  // Handle form submission.
  const handleSubmit = async (event) => {
    setLoading(true)

    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const cardElement = elements.getElement('cardNumber');

    const paymentMethodResponse = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        // name: 'John Smith'
      }
    })

    if(paymentMethodResponse.error) {
      setErrorMessage(paymentMethodResponse.error.message);
      setLoading(false)
    }
    else {
      try {
        const result = await client.post(`/payment_intents/${paymentIntentId}/submit_stripe_payment`, {
          stripePaymentMethodId: paymentMethodResponse.paymentMethod.id,
          savePaymentMethod: (allowUserToSavePaymentMethod && savePaymentMethod)
        }, { withCredentials: true })

        handleServerResponse(result)
      }
      catch(error) {
        if(error.response) {
          setErrorMessage(error.response.data.error)
        }
        else {
          setErrorMessage(error.message)
        }

        setLoading(false)
      }
    }


  };

  const handleServerResponse = async (result) => {
    if(result.data.error) {
      setErrorMessage(result.data.error)
      setCompletedFields({})
    }
    else if(result.data.requiresAction) {
      console.log(`Redirecting to ${result.data.redirectUrl}`)
      window.location = result.data.redirectUrl
    }
    else if(result.data.success) {
      window.location.reload()
    }
    else {
      debugger
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-row">
        { (!activePaymentMethod && paymentRequest) &&
          <div className="stripe-payment-request-buttons">
            <PaymentRequestButtonElement
              options={{paymentRequest, style: { paymentRequestButton: { type: 'default', theme: 'dark', height: '56px' }}}}
            />
          </div>
        }
        { activePaymentMethod === paymentMethod.id ?
          <CardForm
            paymentMethod={paymentMethod}
            handleChange={handleChange}
            savePaymentMethod={savePaymentMethod}
            setSavePaymentMethod={setSavePaymentMethod}
            allowUserToSavePaymentMethod={allowUserToSavePaymentMethod}
            errorMessage={errorMessage}
            errorFields={errorFields}
            completedFields={completedFields}
            showBackToAllPaymentMethods={showBackToAllPaymentMethods}
            onGoBack={() => setActivePaymentMethod(null) }
          />
          :
          <CardButton id="pay-with-stripe-btn" onClick={() => setActivePaymentMethod(paymentMethod.id) } />
        }
      </div>
    </form>
  );
}

const CardForm = ({ handleChange, errorFields, errorMessage, onGoBack, completedFields, savePaymentMethod, setSavePaymentMethod, showBackToAllPaymentMethods, allowUserToSavePaymentMethod, paymentMethod }) => (
  <div>
    <CardButton id="pay-with-stripe-btn" onClick={onGoBack} />

    { errorMessage && <div className="card-errors" role="alert">{errorMessage}</div> }
    <div className={`stripe-card-input-wrapper stripe-card-number ${completedFields['cardNumber'] ? 'completed' : ''} ${errorFields['cardNumber'] ? 'error' : ''}`}>
      <CardNumberElement
        id="card-element"
        options={CARD_ELEMENT_OPTIONS}
        onChange={handleChange}
      />
    </div>
    <div style={{display: 'flex', flexDirection: 'row'}}>
      <div style={{flex: 1}}>
        <div className={`stripe-card-input-wrapper stripe-card-expiration ${completedFields['cardExpiry'] ? 'completed' : ''} ${errorFields['cardNumber'] ? 'error' : ''}`}>
          <CardExpiryElement
            options={CARD_ELEMENT_OPTIONS}
            onChange={handleChange}
          />
        </div>
      </div>
      <div style={{flex: 1}}>
        <div className={`stripe-card-input-wrapper stripe-card-cvc ${completedFields['cardCvc'] ? 'completed' : ''} ${errorFields['cardNumber'] ? 'error' : ''}`}>
          <CardCvcElement
            options={CARD_ELEMENT_OPTIONS}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
    { allowUserToSavePaymentMethod &&
      <SavePaymentMethod
        savePaymentMethod={savePaymentMethod}
        setSavePaymentMethod={setSavePaymentMethod}
        savePaymentMethodMode={paymentMethod.savePaymentMethodMode}
      />
    }
    <button type="submit" className="submit-payment-btn">Submit Payment</button>

    <ShowAllPaymentMethods onClick={onGoBack} showBackToAllPaymentMethods={showBackToAllPaymentMethods} />
  </div>
)

class StripeForm extends React.Component {
  stripePromise = loadStripe(this.props.paymentMethod.stripePublishableKey, { stripeAccount: this.props.paymentMethod.stripeAccountId})

  render() {
    const { show } = this.props

    if(!show) {
      return null
    }

    return (
      <Elements stripe={this.stripePromise}>
        <CheckoutForm {...this.props} />
      </Elements>
    );
  }
}

export default StripeForm
